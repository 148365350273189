/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  overflow: hidden;
}

.mat-tab-group.hide-header .mat-tab-header {
  border-bottom: none !important;
}
.mat-tab-group.hide-header .mat-tab-header .mat-tab-label-container .mat-tab-list .mat-tab-labels .mat-tab-label {
  height: 0px;
}
.mat-tab-group.hide-header .mat-tab-header .mat-tab-header-pagination {
  display: none;
}

.cdk-overlay-pane .mat-dialog-container {
  padding: 0px !important;
}

.mat-dialog-title {
  font-family: "Mulish", sans-serif;
  font-weight: 500;
}

@media only screen and (max-width: 599px) {
  .dialog-w-100 {
    width: 100%;
  }

  .dialog-w-80-p {
    width: 100%;
    max-width: 94vw !important;
  }

  .dialog-wh-80-p {
    width: 100%;
    height: 100%;
  }
}

@media only screen and (min-width: 600px) {
  .dialog-w-80-p {
    width: 80%;
  }

  .dialog-wh-80-p {
    width: 80%;
    height: 80%;
  }
}

/* Hide */
app-root {
  overflow: hidden;
}
app-layout {
  overflow: hidden;
  height: 100%;
  display: block;
}
.mat-drawer-container {
  height: 100%;
}
